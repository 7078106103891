import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './App';
import router from './router';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import store from '@/store';
import { registerModules } from "./register-modules";
import profileModule from "@/modules/profile";
import authModule from "@/modules/auth";
import serviceModule from "@/modules/services";
import menu from "@/menu";
import LoadingSpinner from "@/components/LoadingSpinner";
import AppInfoModule from "@/modules/app-info";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import CodeInput from "vue-verification-code-input";

Vue.config.productionTip = false;

// Bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.component('loading-spinner', LoadingSpinner);
Vue.component('phone-number-input', VuePhoneNumberInput);
Vue.component('code-input', CodeInput);

store.commit("menu/setMenu", menu);
registerModules({
    profile: profileModule,
    auth: authModule,
    services: serviceModule,
    appInfo: AppInfoModule
});


new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});
