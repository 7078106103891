<template>
  <div>
    <b-container class="pt-5">
      <div class="text-center mt-3">
        <h2 class="display-4">Личный кабинет</h2>
      </div>
      <loading-spinner class="mt-5" v-if="loading"/>
      <b-card v-else class="mt-4 text-center block mx-auto login-card">
        <template v-if="authType === 'OTP_AND_LOGIN_PASSWORD'" #header>
          <div class="mb-4" style="font-size: 28px">Вход</div>
          <b-nav card-header tabs small fill>
            <b-nav-item :class="{'pill-active': authUsingOTP}" @click="authUsingOTP = true"
                        :active="authUsingOTP">
              Номер телефона
            </b-nav-item>
            <b-nav-item :class="{'pill-active': !authUsingOTP}" @click="authUsingOTP = false"
                        :active="!authUsingOTP">
              Логин и пароль
            </b-nav-item>
          </b-nav>
        </template>
        <b-card-body v-if="authType === 'OTP_AND_LOGIN_PASSWORD'">
          <otp-auth v-if="authUsingOTP"/>
          <login-password-auth v-else/>
        </b-card-body>
        <otp-auth v-else-if="authType === 'OTP'"/>
        <login-password-auth v-else/>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import OTPAuth from "@/modules/auth/components/OTPAuth";
import LoginPasswordAuth from "@/modules/auth/components/LoginPasswordAuth";
import authService from "@/modules/auth/api/service/auth-service";

export default {
  components: {
    'otp-auth': OTPAuth,
    'login-password-auth': LoginPasswordAuth
  },
  name: 'login',
  data() {
    return {
      authType: null,
      authUsingOTP: true,
      loading: false,
    };
  },
  created() {
    this.loading = true;
    authService.getAuthType()
        .then(response => {
          this.authType = response.data;
        })
        .catch(() => {
          this.authType = "LOGIN_PASSWORD";
        })
        .finally(() => this.loading = false);
  }
}
</script>
<style scoped>
@media only screen and (width > 768px) {
  .login-card {
    width: 50%;
  }
}
.pill-active a {
  color: #FDA701FF !important;
}

/deep/ .nav-item a {
  color: #495057;
}
</style>
