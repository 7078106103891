import Module from "@/modules/services/Module";
import ServicePage from "@/modules/services/views/ServicePage";

export default {
    path: "/service",
    component: Module,
    children: [
        {
            path: 'news',
            component: ServicePage,
            meta: {
                title: 'Новости'
            },
            props: {
                url: process.env.VUE_APP_NEWS_UI_URL
            }
        },
        {
            path: 'user-management',
            component: ServicePage,
            meta: {
                title: 'Пользователи'
            },
            props: {
                url: process.env.VUE_APP_USER_MANAGEMENT_UI_URL
            }
        },
        {
            path: 'catalog',
            component: ServicePage,
            meta: {
                title: 'Каталог'
            },
            props: {
                url: process.env.VUE_APP_CATALOG_UI_URL + "catalog"
            }
        },
        {
            path: 'stock',
            component: ServicePage,
            meta: {
                title: 'Остатки'
            },
            props: {
                url: process.env.VUE_APP_CATALOG_UI_URL + "stock"
            }
        },
        {
            path: 'orders',
            component: ServicePage,
            meta: {
                title: 'Заказы'
            },
            props: {
                url: process.env.VUE_APP_SHOP_ORDER_UI_URL
            }
        },
        {
            path: 'updates',
            component: ServicePage,
            meta: {
                title: 'Обновления'
            },
            props: {
                url: process.env.VUE_APP_UPDATES_UI_URL
            }
        },
        {
            path: 'settings',
            component: ServicePage,
            meta: {
                title: 'Настройки'
            },
            props: {
                url: process.env.VUE_APP_SETTINGS_UI_URL
            }
        }
    ]
};
