<template>
  <div style="width: 100%; height: 99%;">
    <iframe class="iframe" width="100%" height="100%" :src='url'/>
  </div>
</template>
<script>
export default {
  name: 'service-page',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  created() {
    document.title = this.$route.meta.title;
  },
  updated() {
    document.title = this.$route.meta.title;
  }
};
</script>
<style scoped>
@media only screen and (width > 992px) {
  .iframe {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 85%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
    height: 100%;
  }
}
@media only screen and (width < 992px) {
  .iframe {
    top: 0;
    left: 0;
    border: 0
  }
}
</style>
