<template>
  <div style="height: 100vh; display: flex; flex-direction: column">
    <template v-if="isAuthorized">
      <b-navbar class="d-lg-none py-0" style="border-bottom: 3px solid #f5f5f5;" variant="light">
        <b-navbar-nav style="display: grid;">
          <menu-item class="mb-1" v-for="item in getMenu" :key="item.name"
                     :icon="item.icon" :name="item.name"
                     :children="item.children" :path="item.path"
                     :update-admin-only="item.updateAdminOnly"
                     :disable-for-provider="item.disableForProvider"/>
        </b-navbar-nav>
        <b-navbar-nav style="display: grid" class="ml-auto">
          <b-button variant="danger" @click="logoutClicked">Выйти</b-button>
          <b-badge variant="light" style="position: absolute; top: 0; right: 1em">
            <app-info />
          </b-badge>
        </b-navbar-nav>
      </b-navbar>
      <b-sidebar class="d-none d-lg-block" width="15%" id="sidebar-no-header" aria-labelledby="sidebar-no-header-title"
                 shadow visible noCloseOnRouteChange>
        <template #header="{ hide }">
          <b-container class="pl-2 py-1 border-bottom w-100">
            <b-row>
              <b-col>
                <h4 style="font-weight: bold; font-size: 28px; color: #FDA701FF">Меню</h4>
              </b-col>
              <b-col class="pr-0" style="margin: auto">
                <app-info/>
              </b-col>
            </b-row>
          </b-container>
        </template>
        <div class="mt-3">
          <menu-item v-for="item in getMenu" :key="item.name"
                     class="border-bottom mb-1"
                     :icon="item.icon" :name="item.name"
                     :children="item.children" :path="item.path"
                     :update-admin-only="item.updateAdminOnly"
                     :disable-for-manager="item.disableForManager"
                     :disable-for-provider="item.disableForProvider"/>
        </div>
        <template #footer="{ hide }">
          <div class="px-3 py-3">
            <b-button variant="danger" block @click="logoutClicked">Выйти</b-button>
          </div>
        </template>
      </b-sidebar>
    </template>
    <router-view style="flex: 1;"/>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import MenuItem from "@/components/MenuItem";
import AppInfo from "@/modules/app-info/components/AppInfo.vue";

export default {
  name: "app",
  components: {
    "menu-item": MenuItem,
    "app-info": AppInfo
  },
  mounted() {
    document.title = "Личный кабинет";
    window.addEventListener("message", (event) => {
      if (event.data === "LOGOUT") {
        this.unauthorize();
        this.$router.push("/login");
      }
    }, false);
  },
  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("menu", ["getMenu"])
  },
  methods: {
    ...mapMutations("auth", ["unauthorize"]),
    ...mapActions("auth", ["logout"]),
    logoutClicked() {
      this.logout()
          .finally(() => {
            this.$router.push("/login");
          });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/color-scheme.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import '~bootstrap-vue/dist/bootstrap-vue.css';

@media only screen and (min-width: 992px) {
  .page-margin {
    margin-left: 15%;
  }
}
</style>
