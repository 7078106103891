export default [
    {
        name: "Администрирование",
        children: [
            {
                name: "Пользователи",
                icon: "people",
                path: "/service/user-management",
                disableForProvider: true,
                disableForManager: true,
            }
        ]
    },
    {
        name: "Сервисы",
        children: [
            {
                name: "Новости",
                icon: 'b-icon-newspaper',
                path: "/service/news",
                disableForProvider: true,
                disableForManager: true,
            },
            {
                name: "Каталог",
                icon: 'b-icon-cart3',
                path: "/service/catalog",
                disableForProvider: true,
            },
            {
                name: "Остатки",
                icon: 'box-seam',
                path: "/service/stock"
            },
            {
                name: "Заказы",
                icon: 'cash',
                path: "/service/orders"
            },
            {
                name: "Обновления",
                icon: 'b-icon-arrow-down-circle',
                path: "/service/updates",
                updateAdminOnly: true
            },
            {
                name: "Настройки",
                icon: 'b-icon-gear',
                path: "/service/settings",
                disableForProvider: true,
                disableForManager: true,
            }
        ]
    }
]
