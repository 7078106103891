<template>
  <b-table responsive :items="services" :fields="fields">
    <template v-slot:cell(service)="row">
      {{ row.item.name }}
    </template>
    <template v-for="field in fields.slice(1)" v-slot:[`cell(${field})`]="row">
      <b-icon-check-circle class="ml-3" scale="2" v-if="row.item.roles.includes(field)" variant="success"/>
      <b-icon-x-circle class="ml-3" scale="2" v-else variant="danger"/>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "roles-table",
  props: {
    services: {
      required: true,
      type: Array
    },
    allRoles: {
      required: true,
      type: Array
    }
  },
  mounted() {
    this.fields.push(...this.allRoles);
  },
  data() {
    return {
      fields: [
        {
          key: 'service', label: 'Сервис'
        }
      ],
    }
  }
}
</script>
